import React from "react";
import '../assets/style/estatisticas.css';
import Arvore from "../assets/imgs/planta.svg";
import Casa from "../assets/imgs/casa.svg";
import Grafico from "../assets/imgs/grafico.svg";

const Estatisticas = () => {
  return (
    <div className="estatisticas-wrapper">
      
      <div className="estatisticas-container">
        <div className="card-container">
          <div className="estatisticas-header">
            <div>
              <img src={Casa} alt="casa destruída"></img>
            </div>
            <h4>+60.000 pessoas deslocadas</h4>
          </div>
          <div>
            <p>Mais de 60 mil pessoas foram evacuadas de suas residências. Milhares de lares ruíram, esvaziando e devastando bairros inteiros.</p>
          </div>
        </div>
        <div className="card-container">
        <div className="estatisticas-header">
            <div> <img src={Grafico} alt="casa destruída"></img></div>
            <h4>+6.000 empresas afetadas</h4>
          </div>
          <div>
            <p>O desastre causou danos significativos à economia de Maceió, levando ao fechamento de várias empresas e resultando no desemprego de milhares de pessoas. </p>
          </div>
        </div>
        <div className="card-container">
        <div className="estatisticas-header">
            <div> <img src={Arvore} alt="casa destruída"></img></div>
            <h4>Desastre ambiental urbano</h4>
          </div>
          <div>
            <p>Uma área correspondente a 20% da capital alagoana já foi impactada. Este pode ser considerado um dos maiores desastres ambientais urbanos do mundo.</p>
          </div>
        </div>
      </div>
      <div className="home-bottom"></div>
    </div>
  );
};

export default Estatisticas;
