import React from "react";
import "../assets/style/storymap.css";
import { Link } from 'react-router-dom';
import logo from "../assets/imgs/gb.svg";

const StoryMap = () => {
    return (
        <div className="storymap-content">

            <div className="titulos-storymap">

              <h1> Veja todos os poços da Braskem </h1>
              <p> Nossa equipe realizou uma análise minuciosa dos acontecimentos e chegou a fazer um mapa com a localização e algumas informações relevantes dos poços da Braskem em Maceió, contribuindo para uma visualização mais clara da abrangência do dano.</p>
            </div>

          <div className="site-storymap" >
          <iframe title='minas-roadmap' src="https://uploads.knightlab.com/storymapjs/18aa85ea6d0b1af848e7955e69ae394b/minas-da-braskem-sa-em-maceio-al/index.html" />
          </div>

            <a className="map-btn" href="https://uploads.knightlab.com/storymapjs/18aa85ea6d0b1af848e7955e69ae394b/minas-da-braskem-sa-em-maceio-al/index.html" aria-label="roadmap minas braskem">Visite o storymap</a>

        </div>
      );
    };

export default StoryMap;