import React, { useRef, useEffect } from "react";
import '../../assets/style/acoesmodal.css';

const Modal = ({ action, closeModal }) => {

  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);



  return (
    <div className="modal-overlay">
      <div className="modal" ref={modalRef}>
        <span className="close-button" onClick={closeModal}>
          &times;
        </span>

        <div>
          <h2>Detalhes da Ação</h2>
          <p>{action.detalhes}</p>
        </div>

        <div>
        <h2>{action.pdfFiles.length > 0}</h2>
          <ul>
          {action.pdfFiles.map(pdf => (
          <li key={pdf.id}>
            <a href={pdf.path} target="_blank" rel="noopener noreferrer">
              {pdf.name}
            </a>
          </li>
        ))}
            {/* {action.fontes.map((fonte, index) => (
              <li key={index}>
                <a href={fonte.link} target="_blank" rel="noopener noreferrer">
                  {fonte.nome}
                </a>
              </li>
            ))} */}
          </ul>
        </div>
        <button className="fechar-btn" onClick={closeModal}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default Modal;
