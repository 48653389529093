const type = "Button";

const menuItems = [
    {
        label: "Início",
        type: type,
        to: "inicio",
    },
    {
        label: "Ações",
        type: type,
        to: "acoes",

    },
    {
        label: "Contato",
        type: type,
        to: "contato",
    },
    {
        label: "Newsletter",
        type: type,
        to: "newsletter",
    }
];

export default menuItems;
