
import React, { useState } from 'react';
import '../assets/style/contato.css';
import FoneImg from '../assets/imgs/telefone.svg';
const Contato = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log('Dados do Formulário:', formData);


    const data = {
      nome: formData.name,
      email: formData.email,
      mensagem: formData.message,
    };


    try {
      const response = await fetch(
        "https://jacquesgomes.com.br/gb/salvar-contato",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        // Lógica de sucesso

        alert("Mensagem salva com sucesso!");
        console.log("Assinante salvo com sucesso!");
      } else {
        // Lógica de erro
        console.error("Erro ao salvar assinante");
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
      // Resetar o formulário após o envio
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    };
  return (<div className='contato-wrapper'>
    

      <div className='contato-container'>
    
        <div className='contato-col right-col'>
    <div className='contato-img'><img src={FoneImg} alt='fone'></img></div>
    <h3>Ligue-nos, envie um email <br></br>ou preencha o formulário</h3>
    
    <p>(84) 3033 1881</p>
    <p>contato@gabrielbulhoes.com.br</p>
        </div>
        <div className='contato-col'>
        <form onSubmit={handleSubmit}>
        <label>
          Nome
        </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        <label>
          Email
        </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        <label>
          Mensagem
        </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        <button type="submit" className="main-btn">Enviar mensagem</button>
      </form>
    
        </div>
    
      </div>
  </div>
  );
};

export default Contato;
