import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/Home";

import Contato from "./components/Contato";
import Depoimentos from "./components/Depoimentos";
import Acoes from "./components/acoes/Acoes";
import Newsletter from "./components/Newsletter";
import Noticias from "./components/Noticias";
import Escritorio from "./components/Escritorio";
import StoryMap from "./components/StoryMap";

function App() {
  const main = "/";

  const inicio = useRef();
  const acoes = useRef();
  const contato = useRef();
  const newsletter = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      contato,
      acoes,
      newsletter,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "inicio") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />
        <div ref={inicio}>
          <Home />
        </div>

        <div className="body-container">
          <div className="page-content">

            <div ref={acoes}>
              <Acoes />
            </div>
            <div>
              <StoryMap />
            </div>

            {/* <Noticias />



          <Depoimentos /> */}
            <div ref={contato}>
              <Contato />
            </div>

            <div>
              <Escritorio />
            </div>


          </div>      </div>
        <div ref={newsletter}>

          <Footer scrollToComponent={scrollToComponent} />
        </div>
      </div>
    </Router>
  );
}

export default App;
