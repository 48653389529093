import React, { useState } from "react";
import Modal from "./AcoesModal";
import "../../assets/style/acoes.css";
import acoesData from "./acoes.json";

const Timeline = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const openModal = (action) => {
    setSelectedAction(action);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAction(null);
    setModalOpen(false);
  };

  return (
    <div>
      <div className="acoes-titulo">
        <h1>Ações do Escritório</h1>
        <p>Linha do tempo com os eventos e ações do escritório</p>
      </div>

      <div className="timeline-sessão">
        {acoesData.map((acao, index) => (
          <div key={index} className="timeline-content">
            <div className="triangulo-1"></div>

            <div className="sessão-tl">
              <div className="sessão-tl">
                <div className="numeros-card">
                  <h2>{acao.ano}</h2>
                </div>
                <p className="texto-tl">{acao.texto}</p>

                <button
                  className="saibamais-btn"
                  onClick={() => openModal(acao)}
                >
                  Saiba mais
                </button>
              </div>
            </div>
          </div>
        ))}

        {modalOpen && selectedAction && (
          <Modal action={selectedAction} closeModal={closeModal} />
        )}
      </div>
    </div>
  );
};

export default Timeline;
