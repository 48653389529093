// Home.js

import React from "react";
import Maceio from "../assets/imgs/maceio.jpg";
import "../assets/style/home.css";
import Estatisticas from "./Estatisticas";

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-content">
        <div className="home-header">
          <h3>
            Em busca de justiça: o desastre ambiental da{" "}
            <span className="text-highlight">Braskem</span> em Maceió/AL
            <br></br>
            <span class="subtitle"></span>
          </h3>
        </div>

        <Estatisticas />
      </div>
    </div>
  );
};

export default Home;
